import React from 'react';
import { Heart, Users, Briefcase } from 'react-feather';
import { Link } from 'react-router-dom';

function SocialCircle() {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">我的社交圈</h2>
      <div className="space-y-4">
        <Link to="/love-community" className="block bg-gray-100 p-3 rounded-lg hover:bg-gray-200 transition duration-300">
          <h3 className="font-semibold flex items-center"><Heart size={18} className="mr-2 text-pink-500" /> 戀愛社群</h3>
          <p className="text-sm text-gray-600 mt-1">探索浪漫關係的奧秘</p>
        </Link>
        <Link to="/friendship-circle" className="block bg-gray-100 p-3 rounded-lg hover:bg-gray-200 transition duration-300">
          <h3 className="font-semibold flex items-center"><Users size={18} className="mr-2 text-blue-500" /> 友誼圈子</h3>
          <p className="text-sm text-gray-600 mt-1">結識志同道合的朋友</p>
        </Link>
        <Link to="/career-development" className="block bg-gray-100 p-3 rounded-lg hover:bg-gray-200 transition duration-300">
          <h3 className="font-semibold flex items-center"><Briefcase size={18} className="mr-2 text-green-500" /> 職業發展</h3>
          <p className="text-sm text-gray-600 mt-1">拓展你的職業人脈</p>
        </Link>
      </div>
    </div>
  );
}

export default SocialCircle;