import React, { useState } from 'react';
import { User, X } from 'react-feather';
import { calculateLifeNumberChart } from '../services/numerologyService';

function Profile({ user }) {
  const [showFullProfile, setShowFullProfile] = useState(false);
  const lifeNumberChart = calculateLifeNumberChart(user.birthdate);

  const generateLifeNumberChart = () => {
    const numbers = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9],
      [null, 0, null]
    ];
    return (
      <div className="grid grid-cols-3 gap-2 mt-4">
        {numbers.flat().map((num, index) => (
          <div key={index} className="relative">
            {num !== null && (
              <div 
                className={`w-10 h-10 flex items-center justify-center rounded-full relative ${
                  lifeNumberChart[num].count > 0 ? 'bg-purple-500 text-white' : 'bg-gray-200'
                }`}
              >
                {num}
                {lifeNumberChart[num].shape.includes('circle') && <div className="absolute inset-0 border-2 border-blue-500 rounded-full"></div>}
                {lifeNumberChart[num].shape.includes('triangle') && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-6 h-6 border-2 border-green-500 rotate-45"></div>
                  </div>
                )}
                {lifeNumberChart[num].shape.includes('square') && (
                  <div className="absolute inset-0 border-2 border-red-500"></div>
                )}
                {lifeNumberChart[num].count > 1 && (
                  <span className="absolute top-0 right-0 text-xs bg-yellow-500 rounded-full w-4 h-4 flex items-center justify-center">
                    {lifeNumberChart[num].count}
                  </span>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const toggleFullProfile = () => {
    setShowFullProfile(!showFullProfile);
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 cursor-pointer" onClick={toggleFullProfile}>
        <h2 className="text-xl font-semibold mb-4">個人資料</h2>
        <div className="w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-gray-300 flex items-center justify-center mx-auto">
          <User size={48} className="text-gray-600" />
        </div>
        <p className="text-center font-bold mt-4">{user.name}</p>
        <p className="text-center text-gray-600">生日: {user.birthdate}</p>
        <p className="text-center text-gray-600">生命靈數: {user.lifeNumber}</p>
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
        <h2 className="text-xl font-semibold mb-4">生命靈數命盤</h2>
        {generateLifeNumberChart()}
        <p className="mt-4 text-sm text-gray-600">
          圓圈：出現在生日中的數字
          <br />
          菱形：第一次加總的結果
          <br />
          方形：最終的生命靈數
          <br />
          黃字：數字出現大於1的次數
        </p>
      </div>

      {showFullProfile && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">{user.name}的完整個人資料</h2>
              <button onClick={toggleFullProfile} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <div className="mb-4 flex items-center">
              <div className="w-20 h-20 rounded-full bg-gray-300 flex items-center justify-center mr-4">
                <User size={40} className="text-gray-600" />
              </div>
              <div>
                <p className="text-lg font-semibold">{user.name}</p>
                <p className="text-gray-600">生命靈數: {user.lifeNumber}</p>
              </div>
            </div>
            <div className="mb-4">
              <h3 className="font-semibold mb-2">基本資料:</h3>
              <p>生日: {user.birthdate}</p>
              <p>職業: {user.occupation || '未設置'}</p>
              <p>所在地: {user.location || '未設置'}</p>
            </div>
            <div className="mb-4">
              <h3 className="font-semibold mb-2">個性特徵:</h3>
              <ul className="list-disc list-inside">
                <li>深思熟慮</li>
                <li>獨立自主</li>
                <li>富有洞察力</li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-2">興趣愛好:</h3>
              <ul className="list-disc list-inside">
                {user.interests ? user.interests.map((interest, index) => (
                  <li key={index}>{interest}</li>
                )) : <li>未設置</li>}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;