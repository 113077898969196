import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Briefcase } from 'react-feather';

const careerGroups = [
  { id: 1, name: "職業靈數指導", description: "探索生命靈數與職業發展的關係" },
  { id: 2, name: "行業交流網絡", description: "與同行業專業人士建立聯繫" },
  { id: 3, name: "創業者社群", description: "為創業者提供交流和支持平台" },
  { id: 4, name: "職場技能提升", description: "分享職場技能提升的經驗和資源" },
  { id: 5, name: "求職經驗分享", description: "交流求職經驗,分享面試技巧" },
  { id: 6, name: "職業轉型支持", description: "為考慮轉行的人提供建議和支持" },
  { id: 7, name: "遠程工作者聯盟", description: "連接遠程工作者,分享經驗" },
  { id: 8, name: "職場導師計劃", description: "尋找或成為職場導師" },
  { id: 9, name: "工作與生活平衡", description: "討論如何在事業和個人生活間取得平衡" }
];

function CareerDevelopment() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-screen-xl mx-auto px-4 py-6">
        <Link to="/" className="flex items-center text-purple-600 mb-6">
          <ArrowLeft className="mr-2" /> 返回主頁
        </Link>
        <h1 className="text-3xl font-bold mb-6 text-purple-600">職業發展</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {careerGroups.map(group => (
            <div key={group.id} className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-2 flex items-center">
                <Briefcase className="mr-2 text-green-500" /> {group.name}
              </h2>
              <p className="text-gray-600">{group.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CareerDevelopment;