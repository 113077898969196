import React from 'react';
import { Calendar, MapPin, User } from 'react-feather';

const recentTalks = [
  {
    id: 1,
    title: "生命靈數與人際關係",
    speaker: "李大師",
    location: "台北市文化中心",
    date: "2023-05-15 19:00",
  },
  {
    id: 2,
    title: "數字能量在職場的應用",
    speaker: "王教授",
    location: "高雄市圖書館",
    date: "2023-05-20 14:30",
  },
  {
    id: 3,
    title: "靈數與愛情的秘密",
    speaker: "張博士",
    location: "台中市社區中心",
    date: "2023-05-25 18:30",
  },
];

function RecentTalks() {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-6">
      <h2 className="text-xl font-semibold mb-4">最近座談會</h2>
      <div className="space-y-4">
        {recentTalks.map(talk => (
          <div key={talk.id} className="border-b pb-2">
            <h3 className="font-semibold">{talk.title}</h3>
            <p className="text-sm text-gray-600 flex items-center mt-1">
              <User size={14} className="mr-1" /> {talk.speaker}
            </p>
            <p className="text-sm text-gray-600 flex items-center mt-1">
              <MapPin size={14} className="mr-1" /> {talk.location}
            </p>
            <p className="text-sm text-gray-600 flex items-center mt-1">
              <Calendar size={14} className="mr-1" /> {talk.date}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecentTalks;