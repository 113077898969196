import React, { useState } from 'react';
import { User, Heart, MessageCircle, X } from 'react-feather';
import { Link } from 'react-router-dom';

function Matching({ user, matches, setMatches }) {
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [displayCount, setDisplayCount] = useState(6); // 初始顯示 6 個匹配

  // 模擬九個匹配範例
  const sampleMatches = [
    { id: 1, name: '李小花', lifeNumber: 3, compatibility: 85, bio: '喜歡旅行和攝影的樂觀主義者', interests: ['旅行', '攝影', '瑜伽'], birthdate: '1990-05-15', occupation: '攝影師', location: '台北市' },
    { id: 2, name: '王大明', lifeNumber: 7, compatibility: 78, bio: '科技愛好者，喜歡探索新事物', interests: ['科技', '閱讀', '烹飪'], birthdate: '1988-11-22', occupation: '軟體工程師', location: '新竹市' },
    { id: 3, name: '張美玲', lifeNumber: 1, compatibility: 92, bio: '熱愛音樂和藝術的創意靈魂', interests: ['音樂', '繪畫', '舞蹈'], birthdate: '1995-03-10', occupation: '音樂人', location: '台中市' },
    { id: 4, name: '陳俊傑', lifeNumber: 5, compatibility: 70, bio: '運動健身愛好者，追求健康生活', interests: ['健身', '籃球', '健康飲食'], birthdate: '1985-09-25', occupation: '健身教練', location: '高雄市' },
    { id: 5, name: '林雅琪', lifeNumber: 9, compatibility: 88, bio: '環保主義者，熱愛大自然', interests: ['環保', '徒步', '園藝'], birthdate: '1992-07-08', occupation: '環境工程師', location: '台南市' },
    { id: 6, name: '黃志偉', lifeNumber: 2, compatibility: 75, bio: '電影迷和美食愛好者', interests: ['電影', '美食', '寫作'], birthdate: '1987-12-14', occupation: '電影製作人', location: '桃園市' },
    { id: 7, name: '周曉婷', lifeNumber: 6, compatibility: 82, bio: '瑜伽教練，追求身心平衡', interests: ['瑜伽', '冥想', '素食'], birthdate: '1993-01-28', occupation: '瑜伽教練', location: '新北市' },
    { id: 8, name: '劉建國', lifeNumber: 4, compatibility: 79, bio: '創業者，熱衷於商業創新', interests: ['創業', '投資', '網絡營銷'], birthdate: '1983-06-12', occupation: '創業家', location: '苗栗市' },
    { id: 9, name: '吳佳穎', lifeNumber: 8, compatibility: 86, bio: '旅行作家，喜歡探索不同文化', interests: ['寫作', '攝影', '語言學習'], birthdate: '1991-04-02', occupation: '旅行作家', location: '雲林市' },
  ];

  const openProfile = (match) => {
    setSelectedMatch(match);
  };

  const closeProfile = () => {
    setSelectedMatch(null);
  };

  const handleShowMore = () => {
    setDisplayCount(prevCount => Math.min(prevCount + 3, sampleMatches.length));
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="bg-gray-700 text-white py-4 px-6">
        <h2 className="text-xl font-bold">推薦匹配</h2>
      </div>
      <div className="p-4 sm:p-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {sampleMatches.slice(0, displayCount).map(match => (
            <div key={match.id} className="bg-gray-100 rounded-lg p-4 flex flex-col items-center cursor-pointer" onClick={() => openProfile(match)}>
              <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center mb-2">
                <User size={32} className="text-gray-600" />
              </div>
              <h3 className="text-sm font-bold">{match.name}</h3>
              <p className="text-xs text-gray-600">生命靈數: {match.lifeNumber}</p>
              <p className="text-xs text-gray-600">匹配度: {match.compatibility}%</p>
              <div className="flex mt-2 space-x-2">
                <button className="bg-red-400 text-white p-1 rounded-full">
                  <Heart size={14} />
                </button>
                <Link to={`/chat/${match.id}`} className="bg-blue-400 text-white p-1 rounded-full">
                  <MessageCircle size={14} />
                </Link>
              </div>
            </div>
          ))}
        </div>
        {displayCount < sampleMatches.length && (
          <button 
            onClick={handleShowMore} 
            className="w-full bg-gray-700 text-white py-2 px-4 rounded-md mt-4 hover:bg-gray-600 transition duration-300"
          >
            查看更多
          </button>
        )}
      </div>

      {selectedMatch && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">{selectedMatch.name}的個人資料</h2>
              <button onClick={closeProfile} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <div className="mb-4 flex items-center">
              <div className="w-20 h-20 rounded-full bg-gray-300 flex items-center justify-center mr-4">
                <User size={40} className="text-gray-600" />
              </div>
              <div>
                <p className="text-lg font-semibold">{selectedMatch.name}</p>
                <p className="text-gray-600">生命靈數: {selectedMatch.lifeNumber}</p>
                <p className="text-gray-600">匹配度: {selectedMatch.compatibility}%</p>
              </div>
            </div>
            <p className="mb-4">{selectedMatch.bio}</p>
            <div className="mb-4">
              <h3 className="font-semibold mb-2">基本資料:</h3>
              <p>生日: {selectedMatch.birthdate}</p>
              <p>職業: {selectedMatch.occupation}</p>
              <p>所在地: {selectedMatch.location}</p>
            </div>
            <div>
              <h3 className="font-semibold mb-2">興趣愛好:</h3>
              <ul className="list-disc list-inside">
                {selectedMatch.interests.map((interest, index) => (
                  <li key={index}>{interest}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Matching;