import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Heart } from 'react-feather';

const communities = [
  { id: 1, name: "靈數戀愛交流", description: "探討生命靈數與戀愛關係的互動" },
  { id: 2, name: "心靈伴侶尋找", description: "尋找與你靈魂契合的另一半" },
  { id: 3, name: "戀愛成長小組", description: "分享戀愛經驗，共同成長" },
  { id: 4, name: "情侶互動工作坊", description: "學習有效的情侶溝通技巧" },
  { id: 5, name: "單身派對", description: "為單身者提供輕鬆交友的機會" },
  { id: 6, name: "戀愛心理學", description: "從心理學角度理解戀愛關係" },
  { id: 7, name: "長期關係維護", description: "探討如何維持長久穩定的感情" },
  { id: 8, name: "異地戀支持", description: "為異地戀情侶提供支持和建議" },
  { id: 9, name: "復合指南", description: "探討是否和如何與前任復合" }
];

function LoveCommunity() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-screen-xl mx-auto px-4 py-6">
        <Link to="/" className="flex items-center text-purple-600 mb-6">
          <ArrowLeft className="mr-2" /> 返回主頁
        </Link>
        <h1 className="text-3xl font-bold mb-6 text-purple-600">戀愛社群</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {communities.map(community => (
            <div key={community.id} className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-2 flex items-center">
                <Heart className="mr-2 text-pink-500" /> {community.name}
              </h2>
              <p className="text-gray-600">{community.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LoveCommunity;