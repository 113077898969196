import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Users } from 'react-feather';

const circles = [
  { id: 1, name: "靈數友誼配對", description: "基於生命靈數尋找志同道合的朋友" },
  { id: 2, name: "興趣交流群", description: "根據共同興趣結識新朋友" },
  { id: 3, name: "文化交流社", description: "認識來自不同文化背景的朋友" },
  { id: 4, name: "語言學習夥伴", description: "尋找語言交換夥伴" },
  { id: 5, name: "戶外活動團", description: "組織戶外活動,結識喜愛大自然的朋友" },
  { id: 6, name: "讀書會", description: "與書友一起分享閱讀心得" },
  { id: 7, name: "美食探索隊", description: "一起品嚐美食,結識饕客" },
  { id: 8, name: "藝術愛好者", description: "與其他藝術愛好者交流創作" },
  { id: 9, name: "寵物之友", description: "為寵物主人提供交流平台" }
];

function FriendshipCircle() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-screen-xl mx-auto px-4 py-6">
        <Link to="/" className="flex items-center text-purple-600 mb-6">
          <ArrowLeft className="mr-2" /> 返回主頁
        </Link>
        <h1 className="text-3xl font-bold mb-6 text-purple-600">友誼圈子</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {circles.map(circle => (
            <div key={circle.id} className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-2 flex items-center">
                <Users className="mr-2 text-blue-500" /> {circle.name}
              </h2>
              <p className="text-gray-600">{circle.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FriendshipCircle;