export const calculateLifeNumber = (birthdate) => {
  const dateSum = birthdate.split('-').reduce((sum, num) => sum + parseInt(num), 0);
  let lifeNumber = dateSum;
  while (lifeNumber > 9) {
    lifeNumber = lifeNumber.toString().split('').reduce((sum, num) => sum + parseInt(num), 0);
  }
  return lifeNumber;
};

export const calculateLifeNumberChart = (birthdate) => {
  const [year, month, day] = birthdate.split('-');
  const allDigits = year + month + day;
  
  const chart = {
    1: { count: 0, shape: 'circle' },
    2: { count: 0, shape: 'circle' },
    3: { count: 0, shape: 'circle' },
    4: { count: 0, shape: 'circle' },
    5: { count: 0, shape: 'circle' },
    6: { count: 0, shape: 'circle' },
    7: { count: 0, shape: 'circle' },
    8: { count: 0, shape: 'circle' },
    9: { count: 0, shape: 'circle' },
    0: { count: 0, shape: 'circle' },
  };

  // 計算每個數字出現的次數
  allDigits.split('').forEach(digit => {
    chart[digit].count++;
  });

  // 計算第一次加總
  const firstSum = allDigits.split('').reduce((sum, digit) => sum + parseInt(digit), 0);
  const firstSumDigits = firstSum.toString().split('');
  
  // 為第一次加總的結果添加三角形
  firstSumDigits.forEach(digit => {
    if (!chart[digit].shape.includes('triangle')) {
      chart[digit].shape += ' triangle';
    }
  });

  // 計算最終的生命數字
  let finalLifeNumber = firstSum;
  while (finalLifeNumber > 9) {
    finalLifeNumber = finalLifeNumber.toString().split('').reduce((sum, num) => sum + parseInt(num), 0);
  }

  // 為最終生命數字添加方形
  chart[finalLifeNumber].shape += ' square';

  return chart;
};