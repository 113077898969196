import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft, Send, User } from 'react-feather';

function Chat({ user }) {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [currentChat, setCurrentChat] = useState(null);

  // 使用与 Matching 组件相同的示例匹配列表
  const sampleMatches = [
    { id: 1, name: '李小花', lifeNumber: 3, compatibility: 85 },
    { id: 2, name: '王大明', lifeNumber: 7, compatibility: 78 },
    { id: 3, name: '張美玲', lifeNumber: 1, compatibility: 92 },
    { id: 4, name: '陳俊傑', lifeNumber: 5, compatibility: 70 },
    { id: 5, name: '林雅琪', lifeNumber: 9, compatibility: 88 },
    { id: 6, name: '黃志偉', lifeNumber: 2, compatibility: 75 },
    { id: 7, name: '周曉婷', lifeNumber: 6, compatibility: 82 },
    { id: 8, name: '劉建國', lifeNumber: 4, compatibility: 79 },
    { id: 9, name: '吳佳穎', lifeNumber: 8, compatibility: 86 },
  ];

  useEffect(() => {
    if (id) {
      const chatUser = sampleMatches.find(u => u.id === parseInt(id));
      if (chatUser) {
        setCurrentChat(chatUser);
      }
    }
    // 这里可以添加获取历史消息的逻辑
  }, [id]);

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      const newMessage = {
        id: Date.now(),
        text: inputMessage,
        sender: user.name,
        timestamp: new Date().toISOString()
      };
      setMessages([...messages, newMessage]);
      setInputMessage('');
      // 这里可以添加发送消息到服务器的逻辑
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="bg-purple-600 text-white p-4 flex items-center">
        <Link to="/" className="mr-4">
          <ArrowLeft />
        </Link>
        <h1 className="text-xl font-bold flex-grow text-center">
          {currentChat ? `與 ${currentChat.name} 的聊天` : '聊天室'}
        </h1>
      </div>
      <div className="flex flex-col sm:flex-row flex-1">
        <div className="w-full sm:w-64 bg-white border-r">
          <div className="p-4">
            <h2 className="font-bold mb-2">匹配用戶</h2>
            <ul className="space-y-2">
              {sampleMatches.map(match => (
                <li key={match.id} className="flex items-center mb-2">
                  <User size={16} className="mr-2 text-gray-500" />
                  <Link to={`/chat/${match.id}`} className="hover:text-purple-600">
                    {match.name}
                  </Link>
                  <span className="ml-auto text-sm text-gray-500">靈數 {match.lifeNumber}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex flex-col flex-grow">
          <div className="flex-1 overflow-y-auto p-4">
            {messages.map((message) => (
              <div key={message.id} className={`mb-4 ${message.sender === user.name ? 'text-right' : 'text-left'}`}>
                <div className={`inline-block p-2 rounded-lg ${message.sender === user.name ? 'bg-purple-500 text-white' : 'bg-white'}`}>
                  <p className="font-bold">{message.sender}</p>
                  <p>{message.text}</p>
                  <p className="text-xs text-gray-500">{new Date(message.timestamp).toLocaleString()}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-white p-4 flex">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              className="flex-1 border rounded-full px-4 py-2 mr-2"
              placeholder="輸入訊息..."
            />
            <button onClick={handleSendMessage} className="bg-purple-600 text-white rounded-full p-2">
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;