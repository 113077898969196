import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { analyzeUser } from './services/aiService';
import { calculateLifeNumber } from './services/numerologyService';
import Profile from './components/Profile';
import Matching from './components/Matching';
import SocialCircle from './components/SocialCircle';
import RecentTalks from './components/RecentTalks';
import Chat from './components/Chat';
import LoveCommunity from './components/LoveCommunity';
import FriendshipCircle from './components/FriendshipCircle';
import CareerDevelopment from './components/CareerDevelopment';
import { Bell, MessageCircle, User, Settings } from 'react-feather';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  const [user, setUser] = useState(null);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    // 模擬用戶登錄
    const mockUser = {
      id: 1,
      name: '張小明',
      birthdate: '1968-04-20',
      occupation: '軟體工程師',
      location: '台北市',
      interests: ['閱讀', '旅行', '攝影'],
    };
    
    const lifeNumber = calculateLifeNumber(mockUser.birthdate);
    const initialProfile = analyzeUser(mockUser, lifeNumber);
    
    setUser({ ...mockUser, lifeNumber, profile: initialProfile });
  }, []);

  const MainContent = () => (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* 頂部導航欄 */}
      <nav className="bg-gray-800 shadow-md">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center py-4">
            <h1 className="text-2xl font-bold text-white mb-2 sm:mb-0">生命靈數AI社交系統</h1>
            <div className="flex space-x-4">
              <Bell className="text-white cursor-pointer" />
              <MessageCircle className="text-white cursor-pointer" />
              <User className="text-white cursor-pointer" />
              <Settings className="text-white cursor-pointer" />
            </div>
          </div>
        </div>
      </nav>

      {/* 主要內容區 */}
      <div className="flex-1">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="flex flex-col md:flex-row py-4">
            {/* 左側邊欄 - 個人資料 */}
            <aside className="w-full md:w-1/4 mb-4 md:mb-0 md:pr-4">
              {user && <Profile user={user} setUser={setUser} />}
            </aside>

            {/* 中間主區域 - 匹配推薦 */}
            <main className="w-full md:w-1/2 mb-4 md:mb-0 md:px-2">
              {user && <Matching user={user} matches={matches} setMatches={setMatches} />}
            </main>

            {/* 右側邊欄 - 社交圈層和最近座談會 */}
            <aside className="w-full md:w-1/4 md:pl-4">
              <ErrorBoundary>
                <div className="bg-white rounded-lg shadow-md p-6 mb-4">
                  <SocialCircle />
                </div>
                <RecentTalks />
              </ErrorBoundary>
            </aside>
          </div>
        </div>
      </div>

      {/* 底部 - 互動學習系統提示 */}
      <footer className="bg-gray-800">
        <div className="max-w-screen-xl mx-auto px-4">
          <p className="py-4 text-center text-sm text-white">
            互動學習提示: 嘗試與不同生命靈數的人交流，可以幫助你更好地理解自己和他人！
          </p>
        </div>
      </footer>
    </div>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/chat" element={<Chat user={user} />} />
        <Route path="/chat/:id" element={<Chat user={user} />} />
        <Route path="/love-community" element={<LoveCommunity />} />
        <Route path="/friendship-circle" element={<FriendshipCircle />} />
        <Route path="/career-development" element={<CareerDevelopment />} />
      </Routes>
    </Router>
  );
}

export default App;